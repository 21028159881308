@import '@globals/variables'
@import '@globals/breakpoints'

.PostTeaserLink__root
  overflow: hidden
  position: absolute
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  align-items: stretch

  &:after
    content: ""
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 2rem
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)

  &:hover:after
    background-image: none

.PostTeaserLink__content
  flex-grow: 1
  position: relative
