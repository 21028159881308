@import 'components/App/css/variables'
@import 'components/App/css/breakpoints'

.Adition__Container
  display: none
  overflow: hidden

  iframe
    border: 0

.Adition__item,
.Adition__item > div
  width: 100%
  height: 100%

.Adition__Container_billboard
  margin: 0 auto
  max-height: 300px
  max-width: 994px
  @media #{$breakpoint-l}
    display: block

.Adition__Container_special
  display: none
  margin: 25px auto

  @media #{$breakpoint-xl}
    display: block
    height: auto
    width: 100%
    img
      display: block
      width: 100%
      height: auto

.Adition__Container_leaderboard
  display: none
  margin: 3rem auto 1.5rem !important
  max-height: 90px
  max-width: 728px !important
  @media #{$breakpoint-l}
    display: block

.Adition__Container_sky
  display: none
  max-height: 600px
  width: 300px

  img
    display: block
    width: 100%

  @media #{$breakpoint-l}
    display: block
    margin-bottom: 25px

.Adition__Container_rect
  max-height: 250px
  width: 300px
  margin-bottom: 25px

  @media #{$breakpoint-l}
    display: block
  
  img
    display: block
    width: 100%

.Adition__Container_text
  overflow: hidden

  img
    display: none

