@import '@globals/variables'
@import '@globals/breakpoints'

.PostTeaser__root
  display: block
  text-decoration: none
  position: absolute
  width: 100%
  height: 100%
  overflow: hidden
  padding: 0.75rem

  & *
    position: relative !important

  &:hover:after
    border: 4px solid $colors-grayborder
    background-color: rgba(192, 192, 192, 0.1)
    content: ""
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%

  &:hover
    color: $colors-primary
    text-decoration: inherit

  // Fix since Next Link wasn't made for having classnames.
  & a:not(:global .Tag__link)
    text-decoration: inherit

  & .PostTeaser__content
    display: block
    position: relative
    z-index: $zIndex-content

.PostTeaser__byline
  display: block
  font-weight: $fontWeights-default-sans-regular
  font-style: italic
  font-size: calc(13 / $htmlSizes-default * 1rem)
  @media #{$breakpoint-m}
    font-size: calc(15 / $htmlSizes-m * 1rem)

.PostTeaser__audioText_inner
  color: #dda225
  display: none
  margin-left: 2px

  @media #{$breakpoint-m}
    display: initial
