@import '@globals/variables'
@import '@globals/breakpoints'

.PostTeasers__root
  display: flex
  flex-grow: 1
  flex-direction: column
  align-items: stretch

  & :global .PostTeasers__item_2
    display: none

    @media #{$breakpoint-xl}
      display: block

.PostTeasers__items
  flex-grow: 1
  display: flex
  flex-direction: column
  align-items: stretch

.PostTeasers__item
  flex-grow: 1
  display: flex
  position: relative
  margin-bottom: 1.4rem
  min-height: 13rem


