@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.Home__content
  z-index: $zIndex-content
  align-items: stretch
  @include grid(1, 4)

  @if $debug == 1
    background-color: rgba(0, 0, 255, 0.1)

  @media #{$breakpoint-m}
    @include grid(1, 5, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(1, 10, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(1, 12, $grid-default-xl)

.Home__current_issue,
.Home__teasers
  @include grid(1, 4)

.Home__current_issue
  @if $debug == 1
    background-color: rgba(255, 120, 10, 0.5)
