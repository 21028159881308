@import "@globals/variables"

.Tag__link
  text-decoration: none

.Tag__inner
  border-left: 3px solid black
  background-color: $colors-gray

.Tag__txt
  padding: 0.08em 0.5em
