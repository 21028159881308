.Tags__list
  display: flex
  flex-wrap: wrap
  @media print
    display: none

.Tags__item
  z-index: 1
  margin-top: 3px

