@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.Teasers__item
  @include grid-container
  @if $debug == 1
    background-color: yellow

    @media #{$breakpoint-m}
      margin-top: 2.5rem

.Teasers__header
  @include grid(1, 4)

.Teasers__item__index-0 :global
  & .Teaser__root
    border-color: #328637
    @include grid(1, 4)

    @media #{$breakpoint-m}
      @include grid(1, 4, $grid-default-m)

    @media #{$breakpoint-l}
      @include grid(1, 7, $grid-default-l)

    @media #{$breakpoint-xl}
      @include grid(1, 8, $grid-default-xl)

    & ~ .Teaser__root
      border-color: #4f1488

.Teasers__item__index-1 :global
  & .Teaser__root
    border-color: #c52d23
    @include grid(1, 4)

    @media #{$breakpoint-m}
      @include grid(1, 4, $grid-default-m)

    @media #{$breakpoint-l}
      @include grid(1, 4, $grid-default-l)

    @media #{$breakpoint-xl}
      @include grid(1, 4, $grid-default-xl)

    & ~ .Teaser__root
      border-color: #dda225
