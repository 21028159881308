@import "@globals/breakpoints"
@import "@globals/variables"

.MostRead__root
  outline: 4px solid #c52d23
  outline-offset: -10px
  padding: 1rem 1.75rem

  &_mobile
    max-width: 100%
    flex-basis: 100%
    display: block

    @media #{$breakpoint-l}
      display: none

    & li
      margin-bottom: 0.55em

  & ol
    list-style-position: inside
    padding-left: 0

  & li
    &::marker
      font-weight: bold
      font-size: 1.2em

.MostRead__link
  margin: 0

  font-size: 1.2em
  font-weight: lighter
  padding-left: .5em
  text-decoration: none
