@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.WrapperFeaturedShortmessagesHighlightsAds
  @if $debug == 1
    background-color: rgba(0, 0, 255, 0.1)

  @include grid-container
  @include grid(1, 4)

  @media #{$breakpoint-m}
    min-height: 90rem

  @media #{$breakpoint-l}
    min-height: 80rem

  @media #{$breakpoint-xl}
    align-items: stretch

.InnerFeaturedShortmessagesHighlights
  @if $debug == 1
    background-color: rgba(0, 0, 255, 0.1)

  display: flex
  @include grid(1, 4)

  @media #{$breakpoint-m}
    @include grid(1, 8, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(1, 10, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(1, 12, $grid-default-xl)

.InnerAds
  @if $debug == 1
    background-color: rgba(10, 255, 255, 0.5)
  @include grid(1, 4)

  @media #{$breakpoint-m}
    display: none
    margin-top: 7.5rem
    @include grid(7, 8, $grid-default-m)

  @media #{$breakpoint-l}
    display: block
    @include grid(12, 14, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(14, 16, $grid-default-xl)
