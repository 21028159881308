@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.Newsletter__label
  margin-top: 2rem

.Newsletter__title,
.Newsletter__subtitle
  margin-bottom: 1rem

.Newsletter__root
  color: black
  margin-bottom: 2.5rem
  border: 4px solid #154281
  text-align: center

  @include grid(1, 4, $grid-default)

  @media #{$breakpoint-m}
    @include grid(1, 8, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(1, 6, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(1, 8, $grid-default-xl)
