@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.WrapperFeaturedShortmessages
  max-width: 100%
  flex-basis: 100%
  @include grid-container

.InnerFeatured
  @if $debug == 1
    background-color: rgba(0, 0, 255, 0.1)

  @include grid(1, 4)

  @media #{$breakpoint-m}
    height: 50%
    display: flex
    align-items: stretch
    @include grid(1, 6, $grid-home-content-m)

  @media #{$breakpoint-l}
    height: 100%
    @include grid(1, 6, $grid-home-content-l)

  @media #{$breakpoint-xl}
    @include grid(1, 8, $grid-home-content-xl)


  & :global .PostTeasers__item
    min-height: 23rem

    @media #{$breakpoint-m}
      min-height: auto


.InnerShortmessages
  @if $debug == 1
    background-color: rgba(0, 255, 0, 0.1)

  @include grid(1, 6, $grid-home-content-m)

  @media #{$breakpoint-l}
    @include grid(10, 12, $grid-home-content-l)

  @media #{$breakpoint-xl}
    @include grid(10, 12, $grid-home-content-xl)

  & :global .PostTeasers__items
    @media #{$breakpoint-m}
      flex-direction: row

    @media #{$breakpoint-l}
      flex-direction: column

  & :global .PostTeasers__root
    @media #{$breakpoint-l}
      height: 100%
