@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.Teaser__root
  border: 4px solid black
  width: 100%
  text-align: center
  margin-bottom: 2.5rem

.Teaser__label
  margin-top: 2rem
  margin-bottom: 1rem

.Teaser__title
  margin-bottom: 1rem

.Teaser__text, .Teaser__subtitle
  margin-bottom: 1rem

.Teasers__btn
  margin: 0 auto
  padding-top: 0.5rem
  padding-bottom: 1.5rem
