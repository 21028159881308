@import '@globals/variables'


.Button__root
  & .Button__btn
    font-weight: $fontWeights-default-sans-semibold
    padding: 0.7rem 1.5rem
    color: black
    border: 1px solid black

    &:hover
      color: white
      background-color: $colors-primary

  & a.Button__btn
    display: inline-block
    text-decoration: none

    &:hover
      text-decoration: none
