.Image__main
  cursor: pointer
  display: block

.Image__main_scaled
  animation: fadein 0.6s

.Image__wrapper
  position: relative
  width: 100%

.Image__img
  width: 100%
  height: 100%

.Image__wrapper_scaled
  animation: fadein 0.4s
  z-index: 99
  position: fixed
  width: 100vw
  height: 100vh
  background: rgba(1, 1, 1, 0.444)
  top: 0
  left: 0

.Image__inner_scaled
  animation: fadein 0.4s
  padding: 1.5em
  background-color: white
  position: fixed
  top: 50%
  left: 50%
  margin-right: -50%
  transform: translate(-50%, -50%)

@keyframes fadein
  from
    opacity: 0
  to
    opacity: 1


@keyframes scale
  from
    transform: scale(0.33)
  to
    transform: scale(1)

.Image__close_scaled
  display: block
  z-index: 9
  cursor: pointer
  font-size: 3rem
  margin-left: calc(100% - 20px)

  &:hover
    transform: scale(1.15)
    transition: all 0.33s
