@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.WrapperOtherPosts
  @if $debug == 1
    background-color: rgba(0, 0, 255, 0.1)

  display: none
  @media #{$breakpoint-l}
    display: block
    @include grid(1, 14, $grid-default-l)

  & :global .PostTeasers__items
     @include grid-container

  & :global .PostTeasers__item
    min-height: 23rem
    @media #{$breakpoint-l}
      @include grid(1, 4, $grid-default-l)

    @media #{$breakpoint-xl}
      @include grid(1, 4, $grid-default-xl)
