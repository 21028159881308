@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.Evalanche__root
  margin: 0 auto
  width: 68%

  @media #{$breakpoint-m}
    width: 49%
  @media #{$breakpoint-l}
    width: 54%
  @media #{$breakpoint-xl}
    width: 48%


.Evalanche__form button
  display: block
  margin: 0.75rem auto
  padding: 0.75rem 1rem

  @media #{$breakpoint-l}
    padding: 0.35rem 0.75rem

  &:hover
    color: white
