@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.Authors__root
  padding-top: 1.7rem
  padding-bottom: 1.7rem
  border-top: 1px solid black
  border-bottom: 1px solid black

  @media print
    page-break-inside: avoid

.Authors__item
  margin-bottom: 3em

  &:last-child
    margin-bottom: 0

.Author__root
  @include grid-container
  font-size: calc($fontScale-default-small * 1rem)


.Author__img
  display: none
  @media #{$breakpoint-l}
    display: block
    @include grid(1, 2, $grid-default-author)
    border: 4px solid transparent
    box-sizing: content-box
    text-decoration: none

    &:hover
      color: inherit
      text-decoration: none
      border: 4px solid $colors-grayborder

  @media print
    display: block
    margin-bottom: 1em
    @include grid(1, 1, $grid-default-author)

  figure
    height: 100%

  img
    width: 100%
    display: block
    object-fit: cover

.Author__txt
  @include grid(3, 5, $grid-default-author)

  @media print
    @include grid(2, 5, $grid-default-author)

.Author__name,
.Author__info
  font-size: 1em
  line-height: calc(23 / 16)

.Author__name
  font-weight: $fontWeights-default-sans-semibold
  margin-top: 0
  margin-bottom: 0.3em

.AuthorsList__postContent
  font-style: italic
  margin-top: 2.3em
  margin-bottom: 2.5em

  @media #{$breakpoint-m}
    font-size: calc(16 / $htmlSizes-m * 1rem)

  @media #{$breakpoint-l}
    display: none

  @media print
    display: none

.AuhtorList__postTeaser
  font-style: italic

.AuthorDirectory__wrapper,
.SkeletonLoader__wrapper
  @include grid-container
  @include grid(1, 4)

  @media #{$breakpoint-m}
    @include grid(1, 8, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(1, 11, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(1, 13, $grid-default-xl)

  & .SkeletonLoader__root
    min-height: 364px
    margin-bottom: 1.4em

  & .Author__root,
  & .SkeletonLoader__root
    @include grid-container
    @include grid(1, 4, $grid-default-loose)

    @media #{$breakpoint-m}
    @include grid(1, 8, $grid-default-loose-m)

    @media #{$breakpoint-l}
      @include grid(1, 4, $grid-default-loose-l)

    @media #{$breakpoint-xl}
      @include grid(1, 4, $grid-default-loose-xl)

    & > *
      @include grid(1, 4)

  & .Author__name
    font-weight: inherit
    font-size: calc($fontSizes-default-h2 / $htmlSizes-default * 1rem)
    margin-bottom: 2em
    margin-top: 0.7em
    @media #{$breakpoint-m}
      font-size: calc($fontSizes-m-h2 / $htmlSizes-m * 1rem)

  & .Author__img
    display: block


  & img
    width: 100%
    display: block
    object-fit: cover
    max-height: 492px
    height: 492px


    @media #{$breakpoint-m}
      max-height: 331px
      height: 331px


