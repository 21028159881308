@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.Issue__root
  @media #{$breakpoint-m}
    @include grid-container
    align-items: flex-end


.Issue__img
  border: 1px solid black
  line-height: 0
  @media #{$breakpoint-m}
    @include grid(1, 6, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(1, 10, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(1, 10, $grid-default-xl)

.Issue__content
  @media #{$breakpoint-m}
    @include grid(7, 8, $grid-default-m)

  @media #{$breakpoint-l}
    @include grid(12, 14, $grid-default-l)

  @media #{$breakpoint-xl}
    @include grid(12, 16, $grid-default-xl, 1)

.Issue__name
  display: none
  font-size: 5rem
  margin: 0

  @media #{$breakpoint-m}
    display: block


.Issue__info
  justify-content: center

  @media #{$breakpoint-m}
    justify-content: space-between


.Issue__txt
  @include grid(1, 4)
  padding-top: 0.3rem
  padding-bottom: 0.8rem
  p
    margin: 0


.Issue__btn
  @include grid(1.5, 3.5)
