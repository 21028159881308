@import "@globals/grid"
@import "@globals/breakpoints"
@import "@globals/variables"

.WrapperFeaturedShortmessagesHighlights
  @if $debug == 1
    background-color: rgba(0, 128, 128, 0.1)

  max-width: 100%
  flex-basis: 100%
  /* flex-shrink: 1
      flex-grow: 1 */

  @media #{$breakpoint-xl}
    display: flex
    flex-direction: column
    align-items: stretch


.InnerFeaturedShortmessages
  display: flex
  @if $debug == 1
    background-color: rgba(128, 0, 128, 0.1)

  @media #{$breakpoint-m}
    height: 40%
    flex-grow: 4

  @media #{$breakpoint-l}
    height: 60%
    flex-grow: 6


.InnerHighlights
  display: flex
  @if $debug == 1
    background-color: rgba(255, 128, 128, 0.1)

  @media #{$breakpoint-m}
    height: 60%
    flex-grow: 6

  @media #{$breakpoint-l}
    height: 40%
    flex-grow: 4

  & :global .PostTeasers__items
    @include grid-container
    flex-direction: row

  & :global .PostTeasers__item
    min-height: 23rem
    @include grid(1, 4)

    @media #{$breakpoint-m}
      min-height: auto
      @include grid(1, 6, $grid-home-content-m)

    @media #{$breakpoint-l}
      @include grid(1, 5, $grid-home-content-l)

    @media #{$breakpoint-xl}
      @include grid(1, 6, $grid-home-content-xl)
